import { db } from './index';

export function getSiteData(name='', domainType='subdomain'){
  return new Promise((resolve, reject) => {
    name = name.toLowerCase()
    db.collection("sites").where(domainType === 'subdomain' ? 'uniqueSiteName' : 'customDomainName', '==', name).get()
    .then(async res => {
      console.log('res', res)
      let siteDoc
      res.forEach(doc => {
        siteDoc = doc
      })
      if(siteDoc){
        let paymentPlanLevel = await getPaymentPlanLevel(siteDoc.id);
        if(paymentPlanLevel > 0){
          resolve(siteDoc.data())
        }else{
          // their payment plan is not active
          resolve({noSite: true, message: 'Could not render this site. If you are the site owner, please enable "custom domain names" to view your site.'})
        }
      }else{
        resolve({noSite: true})
      }
    }).catch(err => {
      console.warn(err)
      reject()
    })
  })
}

function getPaymentPlanLevel(siteId){
  return new Promise(resolve => {
    resolve(1);
  })
}