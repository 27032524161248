import React, { Component } from "react";
import { SiteRenderer } from "mvptester-templates";
import templateState from "./redux/template_state";
import { getSiteData } from "./firebase/sites";
import ReactGA from 'react-ga';
import hasSubdomain from "./helpers/hasSubdomain";


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      siteData: {data:{}}
    };
  }

  componentDidMount() {
    this.loadData();
    console.log('site loaded')

  }

  loadData = () => {
    let {domain, domainType} = this.getDomain();
    if (domain) {
      getSiteData(domain, domainType)
        .then(data => {
          console.log('data', data)
          if (data.noSite || !data) {
            this.setState({ noSite: true, loading: false });
          } else {
            this.setState({ siteData: data, loading: false }, () => {
              this.initGoogleAnalytics()
              this.initMeta()
            });
          }
        })
        .catch(err => {
          // this.setState({noSite: true, loading: false})
          console.warn(err)
        });
    }else{
      this.setState({loading: false, noSite: true})
    }
  };

  initMeta = () => {
    const { faviconUrl, socialImageUrl, documentTitle } = this.state.siteData.data;
    // console.log('documentTitle', this.state.siteData.documentTitle)

    let favicon = document.getElementById('favicon');
    // let appleFavicon = document.getElementById('apple-favicon');
    let socialImage = document.getElementById('social-image');
    if(faviconUrl){
      favicon.href = faviconUrl+'&v=3';
      // appleFavicon.href = faviconUrl+'&v=3';
    }
    if(socialImageUrl) socialImage.content = socialImageUrl;
    if(documentTitle){
      document.title = documentTitle;
    }
  }

  getDomain = () => {
    let domain = false;
    let domainType = 'subdomain'

    let url = window.location.href;

    // url = 'https://detailer-demo-1.detailpages.com/' // i do this for testing locally
    // url = 'tickeralarm.com';

    url = url.replace('www.', ''); // easier to consistent regex in this format
    if(url.indexOf('http') < 0) url = 'https://'+url; // easier to consistent regex in this format
    var parsedDomain = /:\/\/([^\/]+)/.exec(url)[1]; // 'https://roflmao-69.mvptester.com/' to 'roflmao-69.mvptester.com' seems to remove all before and after stuff
    let parts = parsedDomain.split(".");
    
    if (parts[1]) {
      if (hasSubdomain(url)) {
        domain = parts[0];
      }else{
        // must be custom domain
        domainType='customDomain'
        domain = parts.join('.');
      }
    }

    this.setState({ domain });
    return {domain, domainType};
  };

  initGoogleAnalytics = () => {
    if(this.state.siteData.googleAnalyticsId){
      ReactGA.initialize(this.state.siteData.googleAnalyticsId);
      ReactGA.pageview("LandingPage");
    }
  }

  render() {
    const { loading, noSite } = this.state;
    if (loading) return null;
    if (noSite){
      return (
        <div style={{ textAlign: "center", padding: 50 }}>
          Sorry, we could not find {this.state.domain ? `the site "${this.state.domain}"` : ' that site'}
        </div>
      );
    }
    if(this.state.siteData.siteDisabled) return null;

    // return <App1Template templateData={this.state.siteData} />;
    return <SiteRenderer templateData={this.state.siteData.data} siteData={this.state.siteData} />
  }
}

const styles = {};

export default App;
