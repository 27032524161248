import * as firebase from 'firebase';
// import store from '../redux/store';


var firebaseConfig = {
  apiKey: "AIzaSyCa4A2dQreIYWLRpGZOvM15ateC0QtUIsw",
  authDomain: "mvptester-84a0c.firebaseapp.com",
  databaseURL: "https://mvptester-84a0c.firebaseio.com",
  projectId: "mvptester-84a0c",
  storageBucket: "mvptester-84a0c.appspot.com",
  messagingSenderId: "197362179316",
  appId: "1:197362179316:web:2c98f63de6697b90eed2a1",
  measurementId: "G-4ZJ5KPQ15L"
};
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}else {
  firebase.app(); 
}

let db = firebase.firestore();


export {
  db,
  firebase,
};
